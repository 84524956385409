<template>
  <div class="home">
    <div class="container">
      <div class="home-head d-flex justify-space-between pt-5">
        <img src="../assets/logo.png" alt="">
        <div class="d-flex">
          <div class="header-buttons-desktop">
            <router-link to="/wins">
              <v-btn
                text
                class="white--text"
              >
                Выигранные дела
              </v-btn>
            </router-link>
            <router-link to="/resheniya">
              <v-btn
                color="#fff"
                text
                @click="drawer = false"
              >
                Решения и постановления судов
              </v-btn>
            </router-link>
            <a href="tel:+79383028209" style="color: white; position: absolute; top: 70px; right: 135px;font-size: 15px;">
              <img width="25px" style="margin-right: 10px" src="../assets/icon/phone-call-svgrepo-com.svg" alt="">
              +79383028209
            </a>
            <router-link v-if="1===2" to="/chance">
              <v-btn
                color="#fff"
                text
              >
                ДОСРОЧНЫЙ ВОЗВРАТ ПРАВ
              </v-btn>
            </router-link>
          </div>
          <v-dialog max-width="700">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                rounded
                color="white"
                icon
                title="Дополнительная информация"
              >
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-card>
              <div class="header-buttons">
                <router-link to="/wins">
                  <v-btn
                    text
                    class="white--text"
                  >
                    Выигранные дела
                  </v-btn>
                </router-link>
                <router-link to="/resheniya">
                  <v-btn
                    color="#fff"
                    text
                    @click="drawer = false"
                  >
                    Решения и постановления судов
                  </v-btn>
                </router-link>
                <a href="tel:+79383028209" style="color: white; font-size: 15px;">
                  <img width="25px" style="margin-right: 10px" src="../assets/icon/phone-call-svgrepo-com.svg" alt="">
                  +79383028209
                </a>
                <router-link v-if="1===2" to="/chance">
                  <v-btn
                    color="#fff"
                    text
                  >
                    ДОСРОЧНЫЙ ВОЗВРАТ ПРАВ
                  </v-btn>
                </router-link>
              </div>
              <div class="footer px-6">
                <div>
                  <p style="margin: 0">Бесплатная федеральная линия</p>
                  <a style="font-weight: 800;font-size: 24px;line-height: 33px;margin: 15px 0;display: block;" title="Вызов" href="tel:+78002013236">8-800-201-32-36</a>
                  <p>
                    <a href="https://www.youtube.com/channel/UCQJISs7jzSVMGiNxnNPVuJQ">
                      <img src="../assets/footer/icon-1.png" alt="">Подпишитесь на видеоканал
                    </a>
                  </p>
                  <p>
                    <a href="https://vk.com/club7375007">
                      <img src="../assets/footer/icon.png" alt="">Сотни положительных отзывов
                    </a>
                  </p>
                  <p>
                    <a target="_blank" href="/policy.pdf">
                      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M18 2H12V9L9.5 7.5L7 9V2H6A2 2 0 0 0 4 4V20A2 2 0 0 0 6 22H18A2 2 0 0 0 20 20V4A2 2 0 0 0 18 2M14 12A2 2 0 1 1 12 14A2 2 0 0 1 14 12M18 20H10V19C10 17.67 12.67 17 14 17S18 17.67 18 19Z" />
                      </svg>
                      Пользовательское соглашение
                    </a>
                  </p>
                </div>
                <v-divider class="divider" vertical dark/>
                <div class="right-container">
                  <p style="margin-bottom: 24px">Онлайн-сервисы по возврату прав</p>
                  <p>Бесплатный вебинар по возврату прав</p>
                  <a href="https://xn----7sbbi4acmpdfiyoke2q.xn--p1ai/besplatno-video">Запишитесь прямо сейчас
                    <svg width="17" height="8" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z" fill="#FFED00"/>
                    </svg>
                  </a>
                  <div style="font-size: 10px">
                    *Данные представлены по общему количеству обращений, договоров и результатов отмен постановлений, решений в судах всех инстанций, включая мировой суд по состоянию за период 6 сентября 2021 - 15 декабря 2021 г
                  </div>
                  <!-- p>Решения по такому же делу о лишении прав как Ваше</p -->
                  <!-- a href="https://mypobeda.avtourist.info/">Получите бесплатно
                    <svg width="17" height="8" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z" fill="#FFED00"/>
                    </svg>
                  </a -->
                  <!-- p>Узнай шансы по своему делу за 3 минуты по автоматизированной базе, содержащей 4602 постановления и решения</p -->
                  <!--        <a href="https://mychance.avtourist.info/">-->
                  <!--          Узнать шансы-->
                  <!--          <svg width="17" height="8" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                  <!--            <path d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z" fill="#FFED00"/>-->
                  <!--          </svg>-->
                  <!--        </a>-->
                </div>
              </div>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div class="wrap d-flex flex-column align-center">
        <div class="wrap-title text-sm-h4 text-h5 text-md-h3 text-lg-h3">
          Спор со страховой компанией? Поможем!<br>
          Узнайте как решить спор по возмещению ущерба за 3 минуты!
<!--          <div>-->
<!--            Лишают водительских прав?<br>-->
<!--            Вернём!<br>-->
<!--            Узнайте как вернуть-->
<!--            водительские права за 3 минуты!<br>-->
<!--            Пройдите тест из 7 вопросов-->
<!--          </div>-->
          <span class="mt-5 mt-lg-10 d-block text-sm-h5 text-h6 text-md-h4 text-lg-h4">Пройдите тест из 5 вопросов</span>
        </div>
        <v-dialog
          v-model="dialog.test"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              rounded
              color="yellow"
              class="black--text mt-10"
              style="font-size: 20px"
              width="250px"
              height="50px"
            >
              Пройти тест
            </v-btn>
          </template>
          <v-card class="test-wrap">
            <div class="test-wrap-progress d-flex align-center py-5 py-sm-7 py-md-10 py-lg-12">
              <div class="test-progress">
                <span
                  :style="{
                    left: `${progressValue -2}%`
                  }"
                >
                  {{progressValue}}%
                </span>
                <v-progress-linear color="white" :value="progressValue"></v-progress-linear>
              </div>
              <v-btn
                icon
                text
                @click="dialog.test = false"
                color="white"
                class="ml-10"
                v-if="1===2"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <quest
              v-if="isQuest === 1"
              :is-quest="isQuest"
              question="Есть ли материалы дела"
              :buttons="[
                      'Да, есть',
                      'Нет'
                    ]"
              @result="resultPush"
            />
            <quest
              v-if="isQuest === 2"
              :is-quest="isQuest"
              question="Сколько времени прошло после дтп?"
              :buttons="[
                      'Менее года',
                      'Более года',
                      'Еще не лишен'
                    ]"
              @result="resultPush"
            />
            <quest
              v-if="isQuest === 3"
              :is-quest="isQuest"
              question="Выберите ситуацию"
              @result="resultPush"
            />
<!--            <quest-->
<!--              v-if="isQuest === 5"-->
<!--              :is-quest="isQuest"-->
<!--              question="Город или населенный пункт:"-->
<!--              @result="city"-->
<!--            />-->
            <quest
              v-if="isQuest === 4"
              :is-quest="isQuest"
              :statusSend="statusSend"
              @result="send"
            />
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialog.successSend"
          width="500"
        >

          <v-card>
            <v-card-title class="text-h5 mb-3 green lighten-2 white--text">
              Заявка отправлена <v-icon color="white" class="ml-3">mdi-check-all</v-icon>
            </v-card-title>

            <v-card-text>
              <p style="font-size: 15px">Спасибо! Заявка принята, ожидайте звонка</p>
              <p class="">Наши контакты:</p>
              <a href="tel:+78002013236">Телефон +7 (800) 201 32 36</a><br>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green"
                text
                @click="dialog.successSend = false"
              >
                Ок
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Quest from './quest'
import querystring from 'querystring'
export default {
  name: 'Home',
  components: { Quest },
  data: () => ({
    valid: false,
    statusSend: false,
    form: {
      phone: '',
      email: '',
      city: ''
    },
    nameRules: [
      v => !!v || 'Введите имя',
      v => (v && v.length <= 10) || 'Имя не должно быть длиннее 10 символов'
    ],
    phoneRules: [
      value => !!value || 'Обязательное поле',
      value => (Boolean(Number(value))) || 'Введите только цифры',
      value => (value && value.length >= 11) || 'Не менее 11 цифр',
      value => (value && value.length <= 11) || 'Не более 11 цифр'
    ],
    emailRules: [
      v => !!v || 'E-mail обязательное поле',
      v => /.+@.+/.test(v) || 'E-mail введен не правильно'
    ],
    dialog: {
      wins: false,
      test: false,
      ebsa: false,
      chance: false,
      successSend: false
    },
    progressValue: 0,
    isQuest: 1,
    result: []
  }),
  methods: {
    send (form) {
      this.statusSend = true
      const formData = new FormData()
      formData.append('crm', '11')
      formData.append('pipe', '22')
      formData.append('contact[199]', form.phone)
      formData.append('contact[200]', form.email)
      formData.append('contact[204]', this.form.city)
      formData.append('lead[214]', 'dtp.avtourist.info')
      formData.append('note', 'сайт: dtp.avtourist.info' + '<br>' + this.result.join().replace(/[,]/g, '')) // удаление запятых из this.result

      const formToMail = {
        name: ' -',
        email: form.email,
        phone: form.phone,
        message: this.result.join().replace(/[,]/g, '')
      }
      axios.post('/send.php', querystring.stringify(formToMail)).then(res => (res.status === 200 && console.log('success send to mail')))
      axios.post('https://urist.v-avtoservice.com/api/import-lead',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(res => {
        this.dialog.test = false
        // this.dialog.successSend = true
        this.statusSend = false
        // eslint-disable-next-line
        gtag('event', 'conversion', {'send_to': 'AW-10829377594/UpneCMjwz4wDELrg7Kso'});
        this.$router.push({ name: 'Thanks' })
      })
    },
    resultPush (button) {
      this.result.push(button) // button это текст кнопки, на которую нажали. она и пушится в результат чтоб потом все это отправить
      this.isQuest++ // при ответе на вопрос (нажатие 1 из вариантов ответа) прибавляется цифра, так меняются quest
      this.progressValue += 28 // поднимаем число прогресса
    },
    city (city) {
      this.form.city = city
      this.isQuest++ // при ответе на вопрос (нажатие 1 из вариантов ответа) прибавляется цифра, так меняются quest
      this.progressValue += 30 // поднимаем число прогресса
    },
    backWins () {
      this.dialog.wins = false
    }
  }
}
</script>
<style lang="scss">
  .home {
    .container {
      min-height: 100vh;
      width: 100vw;
      min-width: 100vw;
      background: url("../assets/home_bg.jpg");
      background-size: cover;
      background-position: center;
    }
    .home-head {
      @media (max-width: 500px) {
        flex-wrap: wrap;
        img {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
    .wrap {
      .wrap-title {
        text-align: center;
        font-weight: 700;
        font-size: 2.6875rem;
        line-height: 1.2;
        max-width: 60%;
        margin: 0 auto;
        margin-top: 20%;
        color: white;
        @media (max-width: 500px) {
          margin-top: 100px;
        }
        @media (min-width: 1200px) {
          margin-top: 90px;
        }
        @media (max-width: 1200px) {
          margin-top: 100px;
        }
      }
    }
  }
  .header-buttons-desktop {
    display: flex;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .header-buttons {
    display: none;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), #FFFFFF;
    @media (max-width: 768px) {
      display: flex;
    }
  }
  .test-wrap {
    min-height: 100vh;
    background: url("../assets/home_bg.jpg");
    background-position: center;
    background-size: cover;
    .test-wrap-progress {
      display: flex;
      justify-content: center;
      .test-progress {
        display: flex;
        align-items: center;
        width: 200px;
        position: relative;
        span {
          color: #ffffff;
          position: absolute;
          right: -20px;
          bottom: 12px;
          overflow-wrap: normal;
          transition: all .3s ease;
          padding-bottom: 4px;
          width: 1px;
          &::before {
            content: '';
            line-height: 24px;
            display: block;
            white-space: nowrap;
            font-size: 12px;
            font-weight: 500;
            border-radius: 3px;
            padding: 0 3px;
            width: 100%;
            height: 20px;
            top: 0;
            position: absolute;
          }
          &::after {
            content: "";
            -webkit-transform: translate(-50%);
            transform: translate(-50%);
            position: absolute;
            left: calc(50% + 3px);
            right: auto;
            bottom: -3px;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid currentColor;
            transition: left .3s ease;
          }
        }
      }
    }
  }
  .footer {
    display: grid;
    grid-template-columns: 10fr 10fr;
    @media (max-width: 700px) {
      .divider {
        display: none;
      }
      grid-template-columns: 1fr;
    }
    padding: 40px 0px;
    min-height: 50px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), #FFFFFF;
    color: #ffffff;
    .container {
      display: flex;
      width: 100%;
      @media (max-width: 500px) {
        margin-right: initial;
        flex-wrap: wrap;
      }
    }
    .left-container {
      display: flex;
      align-items: flex-start;
      position: relative;
      width: 50%;
      @media (max-width: 500px) {
        width: 100%;
        justify-content: center;
        margin-bottom: 20px;
      }
      @media (max-width: 1000px) {
        flex-wrap: wrap;
      }
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        background: rgba(255,255,255,.4);
        right: 0;
      }
      div {
        width: 270px;
        max-width: 270px;
      }
      a {
        color: #ffffff;
      }
      svg {
        margin-right: 10px;
      }
      p, a {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        img {
          margin-right: 10px;
        }
      }
    }
    .right-container {
      margin-left: 50px;
      a {
        margin-bottom: 24px;
        color: #FFFFFF;
        text-decoration-line: underline;
        opacity: 0.6;
        display: block;
      }
      p {
        margin: 0;
      }
    }
  }
</style>
