<template>
  <div class="footer">
    <div class="footer__container">
      <div class="left-container">
        <div>
          <a href="https://reiting-uristov.ru">Автоюрист - лидер на проекте<br>РЕЙТИНГ ЮРИСТОВ<br>
            reiting-uristov.ru</a>
          <p>Работаем под контролем Министерства Юстиции РФ</p>
          <p>
            <a target="_blank" style="display: flex; align-items: center" href="https://pristav.mfucentre.ru/minust.pdf">
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.875 18H21.375C20.7536 18 20.25 18.5036 20.25 19.125V28.125C20.25 28.7464 20.7536 29.25 21.375 29.25H25.875C27.1176 29.25 28.125 28.2426 28.125 27V20.25C28.125 19.0074 27.1176 18 25.875 18ZM25.875 27H22.5V20.25H25.875V27Z" fill="#FFED00"/>
                <path d="M36 20.25V18H30.375C29.7536 18 29.25 18.5036 29.25 19.125V29.25H31.5V24.75H36V22.5H31.5V20.25H36Z" fill="#FFED00"/>
                <path d="M2.25 32.6248V3.37477C2.25 2.75342 2.75365 2.24977 3.375 2.24977H20.25V6.74977C20.25 7.99241 21.2574 8.99977 22.5 8.99977H27V12.3748H29.25V7.87477C29.2518 7.57573 29.1343 7.2883 28.9237 7.07602L22.1737 0.326024C21.9615 0.115438 21.674 -0.00191369 21.375 -0.00022619H3.375C1.51102 -0.00022619 0 1.51086 0 3.37477V32.6248C0 34.4888 1.51102 35.9998 3.375 35.9998H11.25V33.7498H3.375C2.75372 33.7498 2.25 33.2461 2.25 32.6248Z" fill="#FFED00"/>
                <path d="M16.875 18H12.375C11.7536 18 11.25 18.5036 11.25 19.125V29.25H13.5V25.875H16.875C18.1176 25.875 19.125 24.8676 19.125 23.625V20.25C19.125 19.0074 18.1176 18 16.875 18ZM16.875 23.625H13.5V20.25H16.875V23.625Z" fill="#FFED00"/>
              </svg>
              Посмотрите наши учредительные документы
            </a>
          </p>
        </div>
      </div>
      <div class="right-container">
        <div class="right-container__title">Бесплатная консультация прямо сейчас</div>
        <div class="social">
          <a target="_blank" href="https://t.me/+79604427897"><img src="../assets/icon/telegram.svg" alt=""></a>
          <a target="_blank" href="https://wa.me/+79604427897"><img src="../assets/icon/whatsapp.svg" alt=""></a>
          <a href="tel:+79604427897">+7 (960) 442 78 97</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped lang="scss">
  .footer {
    width: 100%;
    padding: 40px 0px;
    min-height: 50px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), #FFFFFF;
    color: #ffffff;
    padding-left: 20px;
    @media (max-width: 768px) {
      display: none;
    }
    .footer__container {
      display: flex;
      @media (max-width: 500px) {
        margin-right: initial;
        flex-wrap: wrap;
      }
    }
    .left-container {
      display: flex;
      align-items: flex-start;
      position: relative;
      width: 50%;
      @media (max-width: 500px) {
        width: 100%;
        justify-content: center;
        margin-bottom: 20px;
      }
      @media (max-width: 1000px) {
        flex-wrap: wrap;
      }
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        background: rgba(255,255,255,.4);
        right: 0;
      }
      div {
        width: 270px;
        max-width: 270px;
      }
      a {
        color: #ffffff;
      }
      svg {
        margin-right: 10px;
      }
      p, a {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        img {
          margin-right: 10px;
        }
      }
    }
    .right-container {
      margin-left: 50px;
      .right-container__title {
        margin-bottom: 20px;
      }
      a {
        margin-bottom: 24px;
        color: #FFFFFF;
        text-decoration-line: underline;
        display: block;
      }
      .social {
        display: flex;
        align-items: center;
        font-size: 16px;
      }
      img {
        width: 30px;
        margin-right: 10px;
      }
      p {
        margin: 0;
      }
    }
  }
</style>
